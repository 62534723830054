import React from 'react';
import './styles.css';

import { FaWhatsappSquare, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer id="rodape">
      <div className="principal">
       
        <div className="menu-rodape">

          <ul>
            <h1>Navegação</h1>
            <li><Link className="menu-link" to="/">Home</Link></li>
            <li><Link className="menu-link" to="/servicos">Serviços</Link></li>
            <li><Link className="menu-link" to="/orcamentos">Orçamentos</Link></li>
            <li><Link className="menu-link" to="sobre">Sobre Nós</Link></li>
            <li><Link className="menu-link" to="contatos">Contatos</Link></li>
          </ul>

          <ul>
            <h1>Serviços</h1>
            <li><Link className="menu-link" to="/servicos">Sanitização de Ambientes</Link></li>
            <li><Link className="menu-link" to="/servicos">Controle Integrado de Pragas</Link></li>
            <li><Link className="menu-link" to="/servicos">Perguração de Poços Artesianos</Link></li>
            <li><Link className="menu-link" to="/servicos">Higienização de Reservatório de Água</Link></li>
            <li><Link className="menu-link" to="/servicos">Limpeza de Fossa Séptica</Link></li>
          </ul>

        </div>
        
        <div className="contatos">
          <div className="icones-contatos">
            <a href="https://api.whatsapp.com/send?phone=5598988436656" target="__blank"><FaWhatsappSquare  className="icons" /></a>
            <a href="https://www.facebook.com/Biotec-2102261810002538/" target="__blank"><FaFacebookSquare  className="icons" /></a>
            <a href="https://www.instagram.com/biotec.slz/" target="__blank"><FaInstagramSquare className="icons" /></a>
          </div>
          <Link to="/contatos"><button>Contatos</button></Link>

        </div>
      </div>

      <div className="direitos">
        <h5>2020 - Biotec - Todos direitos reservados</h5>
      </div>
      
    </footer>
  );
}

export default Footer;