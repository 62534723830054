import React from "react";

import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Personagem from "../../components/Personagem";

import sobre from "../../assets/images/sobre.png";
import sobre2 from "../../assets/images/sobre2.png";

import "./styles.css";

const Sobre = () => {
  return (
    <div id="pagina-sobre">
      <main>
        <section className="pagina-1">
          <article
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <h2>A Biotec</h2>
            <h4> </h4>
            <p>
              A <strong>BIOTEC</strong> é uma empresa nacional e genuinamente
              Maranhense - criada e formada por gente que vive, conhece e
              entende a região. Atuante nas áreas de saneamento e do meio
              ambiente e inserida na busca de soluções para os problemas e no
              alcance aos anseios e melhores condições de vida para o povo da
              região.
            </p>

            <p>
              Ao longo dos seus {new Date().getFullYear() - 2013} anos de
              história sempre buscou o aprimoramento e a melhoria contínua dos
              seus serviços e processos visando ser reconhecida como a melhor e
              maior empresa do ramo, respaldada por sua excelência na prestação
              de serviços superando sempre as maiores expectativas de seus
              clientes.
            </p>

            <p>
              Com uma equipe interdisciplinar altamente qualificada, a BIOTEC
              tem expertise para desenvolver estudos e executar serviços com
              qualidade e eficiência, sendo uma das únicas empresas a ter sua
              responsabilidade técnica comprovada através de diversos registros
              e licenciamentos junto a todos os órgãos fiscalizadores.
            </p>
          </article>
          <img
            src={sobre}
            alt="A biotec"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          />
        </section>

        <hr></hr>

        <section className="pagina-2">
          <h2> Nossos serviços</h2>
          <Link to="/servicos">
            {" "}
            <button>Conheça nossos serviços</button>
          </Link>
        </section>

        <hr></hr>

        <section className="pagina-3">
          <img
            src={sobre2}
            alt="A biotec sanitização"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          />
          <article
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <h2> Mais sobre a biotec</h2>
            <p>A BIOTEC oferece soluções inteligentes e customizadas.</p>

            <p>Todas as soluções baseiam-se nos diagnósticos específicos.</p>

            <p>
              Realiza inspeção diagnóstica, identificação do problema,
              determinação do tratamento, controle da praga, comunicação,
              monitoração e documentação. O cliente acompanha todo o processo e
              as informações são organizadas em relatórios disponíveis na web.
            </p>

            <p>
              A BIOTEC com o Programa CIP desenvolve o Controle Integrado de
              Pragas e Sanitização de Ambientes, um sistema ambiental
              sustentável com tecnologias avançadas, formulações químicas
              adequadas e seguras, aplicadas por equipamentos apropriados e de
              última geração.
            </p>
          </article>
        </section>
        <hr></hr>

        <Personagem />
      </main>

      <Footer />
    </div>
  );
};

export default Sobre;
