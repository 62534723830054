import React from 'react';
import { Link } from 'react-router-dom';


import './styles.css';
const ServicoCard = ({ servico }) => {

  return(
    <figure id="card-servico" data-aos="zoom-in-up" data-aos-duration="1000">
        <img src={servico.image} alt={servico.name}/>
        <div className="info">
          <ul>
            <li>{servico.taglist1}</li>
            <li>{servico.taglist2}</li>
            <li>{servico.taglist3}</li>
            <li>{servico.taglist4}</li>
          </ul>
        </div>
        <Link to="/servicos">
          <figcaption>
            <p>{servico.name}</p>
          </figcaption>
        </Link>
      
    </figure>
  );
}

export default ServicoCard;