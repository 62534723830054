import React from 'react';
import { Link } from 'react-router-dom';
import personagemBiotec from '../../assets/images/personagem.png';
import './styles.css';

const Personagem = () => {
  return (
    <div
      className="personagem-container" 
      data-aos="fade-up"
      data-aos-anchor-placement="center-bottom"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      <img src={personagemBiotec} alt="biotec"/>
      <div
        className="personagem-quadro"
      >
          
        <h1>Solicite seu orçamento</h1>
        <h3>É rápido e simples!</h3>
        <Link to="orcamentos">
          <button>Orçamento grátis</button>
        </Link>

      </div>
    </div>
  );
  
}

export default Personagem;