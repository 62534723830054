import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
} from "reactstrap";
import { Link } from "react-router-dom";

import logo1 from "../../assets/images/logo1.png";
import logo2 from "../../assets/images/logo2.png";
import logo3 from "../../assets/images/logo3.png";
import "./styles.css";

const Header = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Navbar className="nav" light expand="md" fixed="top">
      <div className="container">
        <NavbarBrand tag={Link} to="/" className="align">
          <img
            src={logo1}
            width="130"
            className="d-inline-block align-top logo"
            alt="biotec"
          />
          <img
            src={logo2}
            width="130"
            className="d-inline-block align-top logo logo2"
            alt="biotec"
          />
          <img
            src={logo3}
            width="130"
            className="d-inline-block align-top logo logo3"
            alt="biotec"
          />
        </NavbarBrand>
        <NavbarToggler className="navbar-toggler" onClick={toggle} />
        <Collapse isOpen={open} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem tabIndex="0">
              <NavLink className="item" tag={Link} to="/">
                Home
              </NavLink>
            </NavItem>
            <NavItem tabIndex="0">
              <NavLink className="item" tag={Link} to="/sobre">
                Sobre
              </NavLink>
            </NavItem>

            <NavItem tabIndex="0">
              <NavLink className="item" tag={Link} to="/servicos">
                Serviços
              </NavLink>
            </NavItem>

            <NavItem tabIndex="0">
              <NavLink className="item" tag={Link} to="/orcamentos">
                Orçamentos
              </NavLink>
            </NavItem>
            <NavItem tabIndex="0">
              <NavLink className="item" tag={Link} to="/contatos">
                Contatos
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
