import React from "react";

import cliente1 from "../../assets/images/clientes/banco do brasil.jpg";
import cliente2 from "../../assets/images/clientes/adventista.png";
import cliente3 from "../../assets/images/clientes/alfa vile.jpg";
import cliente4 from "../../assets/images/clientes/amoreto.jpg";
import cliente5 from "../../assets/images/clientes/bora burguer.jpg";
import cliente6 from "../../assets/images/clientes/consad.png";
import cliente7 from "../../assets/images/clientes/dasa.png";
import cliente8 from "../../assets/images/clientes/doubos.jpg";
import cliente10 from "../../assets/images/clientes/gracom.png";
import cliente11 from "../../assets/images/clientes/Logotipo_da_TV_Cidade_(São_Luís).png";
import cliente12 from "../../assets/images/clientes/magnilia.jpg";
import cliente13 from "../../assets/images/clientes/mariscado.jpg";
import cliente14 from "../../assets/images/clientes/natureza.png";
import cliente15 from "../../assets/images/clientes/pedreiras.png";
import cliente16 from "../../assets/images/clientes/potiguar.jpg";
import cliente17 from "../../assets/images/clientes/rio anil.png";
import cliente18 from "../../assets/images/clientes/simproesemma.png";
import cliente19 from "../../assets/images/clientes/thumbnail_Outlook-2fsurtqg.png";
import cliente20 from "../../assets/images/clientes/tulios.jpg";
import cliente22 from "../../assets/images/clientes/Valek1.jpg";
import cliente23 from "../../assets/images/clientes/ximenes.png";

import "./styles.css";

const Clientes = () => {
  const clientes = [
    {
      image: cliente1,
      texto: "Banco do Brasil",
    },
    {
      image: cliente2,
      texto: "Adventista",
    },
    {
      image: cliente3,
      texto: "Alfa vile",
    },
    {
      image: cliente4,
      texto: "Amoreto",
    },
    {
      image: cliente5,
      texto: "Bora Burguer",
    },
    {
      image: cliente6,
      texto: "Consad",
    },
    {
      image: cliente7,
      texto: "Dasa",
    },
    {
      image: cliente8,
      texto: "Doubos",
    },
    {
      image: cliente10,
      texto: "Gracom",
    },
    {
      image: cliente11,
      texto: "Tv cidade",
    },
    {
      image: cliente12,
      texto: "Magnilia",
    },
    {
      image: cliente13,
      texto: "Mariscados",
    },
    {
      image: cliente14,
      texto: "Natureza",
    },
    {
      image: cliente15,
      texto: "Pedreiras",
    },
    {
      image: cliente16,
      texto: "Potiguar",
    },
    {
      image: cliente17,
      texto: "Rio Anil",
    },
    {
      image: cliente18,
      texto: "Simproesemma",
    },
    {
      image: cliente19,
      texto: "Bora Burguer",
    },
    {
      image: cliente20,
      texto: "Tulios",
    },
    {
      image: cliente22,
      texto: "Valek",
    },
    {
      image: cliente23,
      texto: "Ximenes",
    },
  ];

  return (
    <picture className="container-image-clientes">
      {clientes.map((cliente, index) => (
        <img
          className="image-clientes"
          src={cliente.image}
          alt={cliente.texto}
          key={index}
        />
      ))}
    </picture>
  );
};

export default Clientes;
