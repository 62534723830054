import React from "react";

import "./styles.css";

import defesa from "../../assets/images/defesa.svg";
import monitoramento from "../../assets/images/monitoramento.svg";
import medalha from "../../assets/images/medalha.svg";
import missao from "../../assets/images/mission.svg";
import visao from "../../assets/images/eye.svg";
import equipe from "../../assets/images/equipe.svg";

import CarouselSlide from "../../components/Carousel";
import Footer from "../../components/Footer";
import TopButton from "../../components/TopButton";
import { Link } from "react-router-dom";
import ServicoCard from "../../components/ServicoCard";
import Parceiros from "../../components/Parceiros";
import Clientes from "../../components/Clientes";
import Personagem from "../../components/Personagem";
import services from "../../services";

const Home = () => {
  return (
    <div id="home" className="conteudo">
      <header data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
        <CarouselSlide />
      </header>
      <h1 className="center titulo-meio">Nosso maior negócio é a sua saúde!</h1>
      <section id="sobre">
        <article
          className="info-home"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <h1>O que fazemos?</h1>
          <p data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            A Biotec é uma empresa de Consultoria e Prestação de serviços na
            área de saneamento e meio ambiente. Oferece soluções em saúde
            ambiental, proporcionando bem-estar a todos o seus clientes.
            Atendendo, de forma rigorosa, a todas as normas dos órgãos
            reguladores competentes, como ANVISA (Agência Nacional de Vigilância
            Sanitária), IBAMA, CONAMA (Conselho Nacional do Meio Ambiente) e
            Secretaria Municipal de Saúde, a Biotec garante a excelência de seus
            serviços e ratifica o seu profissionalismo. A empresa tem como um de
            seus diferencias a presença de engenheiro ambiental , engenheiro de
            segurança do trabalho , engenheiro agrônomo , engenheiro químico em
            seu corpo técnico. Desta forma, assegura um controle ambiental
            efetivo e sem riscos para você, sua família e seus animais de
            estimação. A busca contínua por novas tecnologias e métodos de
            saneamento e meio ambiente, aliada ao aperfeiçoamento constante de
            seus profissionais, fazem da Biotec uma empresa moderna, preparada
            para o futuro. A Biotec tem como pilares de sua atuação a
            proximidade com o cliente e o foco em suas expectativas e
            necessidades. Por meio de uma gestão sustentável, acredita cumprir
            seu papel como agente de evolução social.
          </p>
        </article>

        <div
          className="biotec"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <h1> Porque escolher a biotec?</h1>
          <ul>
            <li>
              ✔ {new Date().getFullYear() - 2013} anos interruptos no mercado.
            </li>
            <li>✔ Ênfase na segurança do trabalho.</li>
            <li>✔ Surpreendemos os mais exigentes clientes.</li>
            <li>✔ Foco na qualidade dos serviços.</li>
            <li>
              ✔ Utilização das melhores técnicas de controle sanitário,
              principalmente de controle de pragas e vetores.
            </li>
            <li>✔ Equipes treinadas e capacitadas.</li>
            <li>✔ A melhor equipe técnica.</li>
          </ul>
        </div>
      </section>

      <div
        className="buttons-info"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        <Link to="/sobre">
          <button className="info-button">Mais sobre a biotec</button>
        </Link>

        <Link to="/orcamentos">
          <button className="orcamento-button">Orçamento</button>
        </Link>
      </div>

      <hr></hr>

      <section
        id="servicos"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        <hgroup className="conteudo-top">
          <h2>Nossos Serviços</h2>
          <Link to="/servicos">
            <button>+ Sobre serviços</button>
          </Link>
        </hgroup>
        <div className="lista-servicos">
          {services.map((servico) => {
            return <ServicoCard key={servico._id} servico={servico} />;
          })}
        </div>
      </section>

      <div id="info-container">
        <div
          id="info"
          data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          data-aos-delay="50"
          data-aos-mirror="true"
        >
          <hgroup className="info-texto">
            <img src={missao} alt="Missão" className="imagens" />
            <h2>Missão</h2>
            <p>
              Proporcionar soluções ambientais sustentáveis aos nossos clientes,
              parceiros, e sociedade. Buscando desenvolvimento equilibrado e
              consciente.
            </p>
          </hgroup>
        </div>

        <div
          id="info2"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          data-aos-delay="50"
          data-aos-mirror="true"
        >
          <hgroup className="info-texto">
            <img src={visao} alt="visão" className="imagens" />
            <h2>Visão</h2>
            <p>
              Ser referência nacional em nossas atividades, ser reconhecida pela
              sua competência, qualidade, diversidade de soluções, aliada a
              transparência e responsabilidade socioambiental.
            </p>
          </hgroup>
        </div>

        <div
          id="info"
          data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          data-aos-delay="50"
          data-aos-mirror="true"
        >
          <hgroup className="info-texto">
            <img src={equipe} alt="valores" className="imagens" />
            <h2>Valores</h2>
            <p>
              ✔ Ética e transparênca no relacionamento com clientes,
              fornecedores, e colaboradores
            </p>
            <p>✔ Respeito a vida</p>
            <p>✔ Qualidade e Excelênca em serviços</p>
            <p>✔ Comprometimento</p>
          </hgroup>
        </div>
      </div>
      <section></section>
      <h1 className="title-text center"> Nossos diferencias </h1>
      <div className="cards">
        <div
          className="card"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-anchor-placement="top-center"
        >
          <img src={medalha} alt="Utilização das mais modernas tecnologias" />
          <span>
            • Utilização das mais modernas tecnologias, com máximo de eficiência
            e o mínimo de uso de produtos, inclusive com aplicação de técnicas
            biológicas.
          </span>
        </div>

        <div
          className="card"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-anchor-placement="top-center"
        >
          <img src={defesa} alt="Foco na Prevenção e controle" />
          <span>
            • Foco na prenvenção e controle, principalmente nas áreas de
            alimentação hospitais, entidades de ensino, indústria, comércio e
            serviços.
          </span>
        </div>

        <div
          className="card"
          data-aos="fade-left"
          data-aos-duration="800"
          data-aos-anchor-placement="top-center"
        >
          <img
            src={monitoramento}
            alt="Monitoramento e controle integrado de pragas"
          />
          <span>
            • Executamos e monitoramos o Controle Integrado de Pragas a médio e
            longo prazos, sob contrato, com expressiva redução de custos.
          </span>
        </div>
      </div>

      <Personagem />

      <hr></hr>

      <section id="clientes">
        <h2>Nossos Parceiros</h2>
        <h5>
          Desenvolvemos parceria sólidas e duradouras, com os mais conceituados
          laboratórios da atualidade:
        </h5>
        <Parceiros />
      </section>

      <hr></hr>

      <section id="clientes">
        <h2>Nossos Clientes</h2>
        <h5>
          Quem possui qualidade no que faz, também exige um serviço de qualidade
          e contrata a BIOTEC:
        </h5>
        <Clientes />
      </section>

      <hr></hr>

      <Footer />
      <TopButton />
    </div>
  );
};

export default Home;
