const services = [
  {
    _id: 1,
    servicePath: "/sanitizacao-de-ambientes",
    image: "/images/fotovoltaica.jpg",
    name: "Energia Fotovoltaica",
    taglist1: "✔ Baixa manutenção",
    taglist2: "✔ Economia imediata",
    taglist3: "✔ Retorno do investimento",
    taglist4: "✔ Limpa e silenciosa",
    description:
      "Sistema que utiliza um geradores de energia solar formado por inversores e painéis solares que são produzidos a partir de silício para converter a radiação solar em energia elétrica. A energia fotovoltaica é uma das fontes de energia limpa que mais cresce atualmente; A energia solar é uma forma de energia gerada pelo calor e luz do sol, sendo utilizada por meio de diversas tecnologias. Trata-se de uma fonte de energia sustentável e inesgotável cuja aplicação cresce gradativamente em escala global. No Brasil, a geração de energia solar está crescendo exponencialmente. Segundo pesquisa realizada pelo IPEA (Instituto de Pesquisa Econômica Aplicada), a participação da energia do sol na matriz energética brasileira foi de 0,1% em 2016 para 1,2% em 2019.",
  },
  {
    _id: 2,
    servicePath: "/sanitizacao-de-ambientes",
    image: "/images/sanitizacao-ambientes.png",
    name: "Sanitização de Ambientes",
    taglist1: "✔ Elimina o Covid-19",
    taglist2: "✔ Elimina bactérias e fungos",
    taglist3: "✔ Recomendado pela MS/ANVISA",
    taglist4: "✔ Indicado para todos os tipos de ambientes",
    description:
      "A sanitização de ambientes é uma ação eficiente contra a presença de vírus, bactérias e fungos nos ambientes que tanto prejudicam a nossa saúde. Agora com recomendação da MS/ANVISA para contribuir no efetivo combate ao novo Coronavirus / COVID-19. Pode ser realizada em áreas Internas - residências, academias, escritórios, lojas, indústrias, clínicas, consultórios, hospitais, estações de ônibus, etc.) e em áreas externas - Pontos de ônibus abertos, calçadas, bancos de praças, academias a céu aberto, bancas de revistas, entradas de lojas e outros pontos de acumulação de pessoas. Somente empresas licenciadas pela Vigilância Sanitária e no órgão ambiental (DVISA/SEMAS/SEMMA) e profissionais treinados e capacitados podem realizar este serviço, utilizando aparelhos de ponta e equipamentos de proteção individual (EPI) adequados, sob a orientação de um profissional habilitado que assinará o certificado de Sanitização.",
  },
  {
    _id: 3,
    servicePath: "/controle-de-pragas",
    image: "/images/controle-de-pragas.png",
    name: "Controle Integrado de Pragas",
    taglist1: "✔ Modernas tecnologias, máxima eficiência",
    taglist2: "✔ Aplicações de técnicas biológicas",
    taglist3: "✔ Foco na prevenção e controle",
    taglist4: "✔ Expressiva redução de custos",
    description:
      "Para um ambiente protegido contra pragas urbanas, executamos os serviços de desinsetização, desratização e descupinização com as melhores técnicas e produtos, garantindo assim um ambiente saudável e imune da presença dos vetores de graves doenças que atingem nossa população.",
  },
  {
    _id: 4,
    servicePath: "/perfuracao-de-poco",
    image: "/images/perfuracao-poco.jpg",
    name: "Perfuração de Poços Artesianos",
    taglist1: "✔ Elaboração de projetos de perfuração",
    taglist2: "✔ Estudos hidrogeológicos detalhados",
    taglist3: "✔ Utilização de equipamentos de última geração",
    taglist4: "",
    description:
      "A perfuração de poços artesianos é um serviço indispensável para aqueles que desejam captar água subterrânea com segurança e eficiência. A qualidade da água e a relação custo/benefício tornam os poços tubulares uma das melhores alternativas para abastecimento de água. Este serviço é largamente procurado por diversos segmentos do mercado e ambientes, como: Indústrias; Empresas; Condomínios; Fazendas, chácaras e sítios; Propriedade urbanas. Normalmente, a água obtida não precisa passar por tratamentos químicos para ser utilizada, pois as rochas atuam como filtros naturais. Basta realizar a análise laboratorial da água para confirmar se seus parâmetros encontram-se de acordo com os estabelecidos pela legislação.",
  },
  {
    _id: 5,
    servicePath: "/limpeza-de-fossa-septica",
    image: "/images/higienizacao-fossa-septica.jpg",
    name: "Limpeza da Fossa Séptica",
    taglist1: "✔ Evita o mau cheiro e doenças",
    taglist2: "✔ Prevenir a chegada de insetos",
    taglist3: "✔ Impedir a contaminação da água e do solo",
    taglist4: "✔ Prevenir problemas mais graves na tubulação e no ramal",
    description:
      "A limpeza da fossa séptica deve ser realizada, em média, uma vez por ano, variando de acordo com o tamanho do tanque e a intensidade do uso. O ideal é que esse trabalho seja realizado por uma desentupidora especializada, e por profissionais experientes, para que você não corra o risco de se contaminar no processo",
  },
  {
    _id: 6,
    servicePath: "/limpeza-de-reservatorio-de-agua",
    image: "/images/limpeza-reservatorio.jpg",
    name: "Higienização de Reservatório de Água",
    taglist1: "✔ Prevenção contra pragas e doenças",
    taglist2: "✔ Elimina bactérias e fungos",
    taglist3: "✔ Impedir a contaminação da água",
    taglist4: "✔ Recomendado pela MS/ANVISA",
    description:
      "O serviço de higienização de reservatório de água potável tem como objetivo a sanitização e a desinfecção de reservatórios de água em ambientes residenciais, comerciais ou industriais. A conservação da qualidade da água potável e de seu consumo seguro pelo ser humano depende muito do processo de limpeza de reservatório de água potável",
  },
];

export default services;
