import React from "react";

import parceiro1 from "../../assets/images/parceiros/BASF.png";
import parceiro2 from "../../assets/images/parceiros/BAYER.png";
import parceiro3 from "../../assets/images/parceiros/chemone.png";
import parceiro4 from "../../assets/images/parceiros/novartis.png";
import parceiro5 from "../../assets/images/parceiros/syngenta.png";

import "./styles.css";

const Parceiros = () => {
  const parceiros = [
    {
      id: 1,
      image: parceiro1,
      texto: "BASF",
    },
    {
      id: 2,
      image: parceiro2,
      texto: "BAYER",
    },
    {
      id: 3,
      image: parceiro3,
      texto: "Chemone",
    },
    {
      id: 4,
      image: parceiro4,
      texto: "Novartis",
    },
    {
      id: 5,
      image: parceiro5,
      texto: "syngenta",
    },
  ];

  return (
    <picture className="container-image-parceiros">
      {parceiros.map((parceiro, index) => (
        <img
          className="image-parceiros"
          src={parceiro.image}
          alt={parceiro.texto}
          key={index}
        />
      ))}
    </picture>
  );
};

export default Parceiros;
