import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./styles.css";

const CarouselSlide = () => {
  return (
    <div className="teste">
      <Carousel>
        <Carousel.Item className="carousel-item">
          <img
            className="d-block w-100 h-100"
            src="/images/imagem1.jpg"
            alt="Primeira foto"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 h-100"
            src="/images/imagem2.png"
            alt="Segunda foto"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/imagem3.png"
            alt="Terceira foto"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/imagem4.png"
            alt="Quarta foto"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/imagem5.png"
            alt="Quinta foto"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/imagem6.png"
            alt="Quinta foto"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/imagem7.jpg"
            alt="Quinta foto"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/imagem8.jpg"
            alt="Quinta foto"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselSlide;
