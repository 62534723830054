import React, {useState} from 'react';

import './styles.css';

import top from '../../assets/images/top.svg';


const TopButton = () => {
  const [showScroll, setShowScroll] = useState(false)
  const checkScrollTop = () => {    
    if (!showScroll && window.pageYOffset > 400){
       setShowScroll(true)    
    } else if (showScroll && window.pageYOffset <= 400){
       setShowScroll(false)    
    }  
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
    <img
      src={top}
      alt="scroll top"
      className="scrollTop" 
      onClick={scrollTop} 
      style={{height: 50, display: showScroll ? 'flex' : 'none'}}
    >

    </img>
  );
}
export default TopButton;