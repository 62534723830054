import React from 'react';

import { HiOutlineMail, HiOutlineLocationMarker } from 'react-icons/hi';
import { FiPhoneCall } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';

import whatsapp from '../../assets/images/logos_whatsapp.svg';
import endereco from '../../assets/images/endereco.png';


import './styles.css';

const Contatos = () => {
  function removeBorder(){
    const tabItems = document.querySelectorAll('.tab-item');
    tabItems.forEach( item => {
      item.classList.remove('tab-border');
    });
  };

  function removeShow(){
    const tabContentItems = document.querySelectorAll('.tab-content-item');
    tabContentItems.forEach( item => {
      item.classList.remove('show')
    })
  }
  
  function handleClick(e){
    removeBorder();
    removeShow();
    const click = e.target
    click.classList.add('tab-border')

    const tabContentItem = document.querySelector(`#${click.id}-content`);
    
    tabContentItem.classList.add('show');
  }

  
  return (
    
    <div id="contact-page" className="conteudo">
      <main>
        <section className="tabs">
          <div className="tab-container" >

            <div id="tab-1" className="tab-item tab-border" onClick={handleClick}>
              <FaWhatsapp className="no-click"/>
              <p className="hide no-click">Whatsapp</p>
            </div>

            <div id="tab-2" className="tab-item" onClick={handleClick}>
              <FiPhoneCall className="no-click"/>
              <p className="hide no-click">Telefone</p>
            </div>
            
            <div id="tab-3" className="tab-item" onClick={handleClick}>
              <HiOutlineMail className="no-click"/>
              <p className="hide no-click">Email</p>
            </div>

            <div id="tab-4" className="tab-item" onClick={handleClick}>
              <HiOutlineLocationMarker className="no-click"/>
              <p className="hide no-click">Localização</p>
            </div>

          </div>
        </section>

        <section className="tab-content">
          <div className="tab-container">

            <div id="tab-1-content" className="tab-content-item show">
              <h1>Nosso whatsapp</h1>
              <p>Você pode falar conosco clicando no botão abaixo</p>
              <a href="https://api.whatsapp.com/send?phone=5598988436656" target="__blank">
              <img src={whatsapp} alt="whatsapp" className="whatsapp-button animate__animated animate__tada animate__infinite" />
              </a>
            </div>

            <div id="tab-2-content" className="tab-content-item">
              <h1>Telefone</h1>
              <p><a href="tell:98988436656">(98) 98843-6656</a></p>
              <p><a href="tell:9832467613">(98) 3246-7613</a></p>
            </div>

            <div id="tab-3-content" className="tab-content-item">
              <h1>Email de contato</h1>
              <p><a href="mailto:comercial@biotecaengenharia.com.br">comercial@biotecaengenharia.com.br</a></p>
            </div>

            <div id="tab-4-content" className="tab-content-item">
              <h1>Endereço:</h1>
              <p>R. Retiro Natal, nº 14</p>
              <p>Recanto Fialho</p>
              <p>Cep: 65073-715</p>
              <p>São Luís - MA </p>
              <img src={endereco} alt="endereço" className="endereco"/>
            </div>
          </div>

        </section>
      </main>
    </div>
  );
}

export default Contatos;