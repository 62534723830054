import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Servicos from "./pages/Servicos";
import Sobre from "./pages/Sobre";
import Orcamentos from "./pages/Orcamentos";
import Contatos from "./pages/Contatos";

import Header from "./components/Header";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Routes = () => {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/servicos" component={Servicos} />
        <Route path="/orcamentos" component={Orcamentos} />
        <Route path="/sobre" component={Sobre} />
        <Route path="/contatos" component={Contatos} />
      </Switch>
    </Router>
  );
};

export default Routes;
