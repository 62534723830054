import React, { useState } from "react";
import emailjs from "emailjs-com";
import Footer from "../../components/Footer";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/comfirm.json";

import "./styles.css";

const Orcamentos = () => {
  const [confirmEmail, setConfirmEmail] = useState(null);
  const history = useHistory();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_zdfh30g",
        e.target,
        "user_Lih3xux5Sa1Cp02O26NS4"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setConfirmEmail(true);
    setTimeout(() => {
      history.push("/");
    }, 5000);
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div id="orcamento-form" className="conteudo">
      {confirmEmail && (
        <div id="fundo">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      )}

      <main>
        <form onSubmit={sendEmail}>
          <h1>Faça seu orçamento</h1>
          <fieldset>
            <legend>
              <h2>Seus Dados</h2>
            </legend>
            <div className="field">
              <label htmlFor="nome">Nome</label>
              <input type="text" name="nome" id="nome" required />
            </div>

            <div className="field-group">
              <div className="field">
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" id="email" required />
              </div>

              <div className="field">
                <label htmlFor="telefone">Telefone</label>
                <input type="text" name="telefone" id="telefone" required />
              </div>
            </div>

            <div className="field">
              <label htmlFor="endereco">Endereço</label>
              <input type="address" name="endereco" id="endereco" required />
            </div>

            <div className="field">
              <label htmlFor="services">Serviços</label>
              <select name="servico" id="servico" required>
                <option hidden>Selecione</option>
                <option>Energia Fotovoltaica</option>
                <option>Sanitização de Ambientes</option>
                <option>Controle Integrado de Pragas</option>
                <option>Perfuração de Poços Artesianos</option>
                <option>Limpeza da Fossa Séptica</option>
                <option>Higienização de Reservatório de Água</option>
              </select>
            </div>

            <div className="field">
              <label htmlFor="mais-info">Mais informações</label>
              <textarea
                type="textarea"
                name="mais-info"
                id="mais-info"
                placeholder="Aqui você pode descrever melhor o que você precisa"
              />
            </div>
          </fieldset>

          <button type="submit">Enviar orçamento</button>
        </form>
      </main>
      <Footer />
    </div>
  );
};

export default Orcamentos;
