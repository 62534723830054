import React from 'react';
import Routes from './routes';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  AOS.init();
  return (
    <Routes />
  );
}

export default App;
