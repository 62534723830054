import React, { useState } from "react";
import services from "../../services";
import "./styles.css";
const Servicos = () => {
  const [selectedService, setSelectedService] = useState(services[0]);
  console.log({ selectedService });
  function handleClick(id) {
    const service = services.find((service) => service._id === id);
    setSelectedService(service);
  }

  return (
    <div id="pagina-de-servicos" className="conteudo">
      <nav class="menu-servicos" tabindex="0">
        <div class="smartphone-menu-trigger"></div>
        <h1>Serviços</h1>
        <ul>
          {services.map((servico, index) => {
            return (
              <li
                key={index}
                tabindex="0"
                id={`list-${servico._id}`}
                className="list-item"
                onClick={() => handleClick(servico._id)}
              >
                {servico.name}
              </li>
            );
          })}
        </ul>
      </nav>

      <main>
        <div className="servico-descricao list-content-item">
          <h1>{selectedService.name}</h1>
          <section className="informacoes-servico">
            <img src={selectedService.image} alt={selectedService.name} />
            <ul>
              <li>{selectedService.taglist1}</li>
              <li>{selectedService.taglist2}</li>
              <li>{selectedService.taglist3}</li>
              <li>{selectedService.taglist4}</li>
            </ul>
          </section>
          <div className="sobre-servico">
            <h1>Como funciona?</h1>
            <p>{selectedService.description}</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Servicos;
